export default {
  params: ["key", "optionList"],
  validate(value, [ key, optionList ]) {
    if (!value || !optionList) {
      return true;
    }

    // 自分以外で重複があればNG
    return !Object.values(optionList).some(
      (option) => option.name != key && String(option.unitCode) === value,
    );
  },
};
