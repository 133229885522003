<template>
  <validation-observer v-slot="{ invalid }">
    <div class="grid-1of1">
      <div class="board large">
        <h2 class="heading--tertiary">{{ title }}送信</h2>
        <slot name="nativeBody" />
        <div class="section">
          <label>件名<required-mark /></label>
          <!-- dummyのinputタグで対象のv-modelをバイドしないとveeValidateが正常動作しないため追加 -->
          <input type="text" v-model="input.subject" hidden/>
          <atom-input
            v-model="input.subject"
            placeholder="件名"
            :disabled="disableSubject"
            :rules="{ required: true, max: 255 }"
            :messages="{
              required: '必須',
              max: '255文字以下',
            }"
          />
        </div>
        <div class="section">
          <label>本文<required-mark /></label>
          <!-- dummyのinputタグで対象のv-modelをバイドしないとveeValidateが正常動作しないため追加 -->
          <input type="text" v-model="input.body" hidden />
          <atom-textarea
            v-model="input.body"
            placeholder="本文"
            :disabled="disableBody"
            :rules="{ required: true }"
            :messages="{
              required: '必須',
            }"
          />
        </div>
        <div class="section last-section">
          <label class="send-start-date__label">
            送信開始日時<required-mark />
          </label>
          <div class="send-start-date__input">
            <input
              id="send-now"
              v-model="input.sendNow"
              type="radio"
              value="now"
            />
            <label for="send-now">今すぐ</label>
            <input
              id="send-specify"
              v-model="input.sendNow"
              type="radio"
              value="specify"
              :disabled="disableSendDateTime"
            />
            <label
              for="send-specify"
              :class="{ disabled: disableSendDateTime }"
            >
              日時を指定する
            </label>
          </div>
        </div>
        <div v-show="!sendNow" class="send-date-time-wrap">
          <div>
            <label class="section-label">
              <span>日時<required-mark /></span>
              <atom-date-time-picker
                :model-value="input.sendDateTime"
                :starting-view-date="defaultValue"
                :disabled="sendNow"
                :disabled-dates="{ predicate: notBeforeToday }"
                :disabled-time="{ predicate: notBeforeTime }"
                :rules="requiredDateTime"
                :messages="{
                  required: '必須',
                }"
                @update:model-value="input.sendDateTime = $event"
              />
            </label>
          </div>
        </div>
        <div class="board__button">
          <atom-button
            v-show="doCancel"
            class="button--circle--white"
            @click="onClickSendCancel"
          >
            送信予約を取り消し
          </atom-button>
          <atom-button
            :disabled="invalid && necessaryValidation"
            @click="onClickNext"
          >
            確認
          </atom-button>
        </div>
      </div>
    </div>

    <description class="footer-description">
      {{ description }}
    </description>

    <modal
      v-model:is-visible="displayConfirmModal"
      modal-window-style="width: 80vw;"
    >
      <template #header> 登録内容の確認 </template>
      <template #body>
        <slot name="modalNativeBody" />
        <h3>件名</h3>
        <span class="is-size-7 modal-text">{{ input.subject }}</span>
        <h3>本文</h3>
        <span class="is-size-7 mail-body">{{ input.body }}</span>
        <br />
        <br />
        <p>
          <span class="is-size-7 modal-text">URL: {{ loginUrl }}</span>
        </p>
        <p>
          <span class="is-size-7">個人ID / Individual ID: ◯◯◯◯</span>
        </p>
        <p v-show="needPassword">
          <span class="is-size-7">パスワード / Password: ＊＊＊＊＊＊＊＊</span>
        </p>
        <p>
          <span class="is-size-7 mail-body">{{ mailSignature }}</span>
        </p>
        <br />
        <h3>送信開始日時</h3>
        <p>
          <span class="is-size-7">{{ modalSendReservedAt }}</span>
        </p>
      </template>
      <template #footer>
        <atom-button @click="onClickCreate">
          {{ confirmButtonLabel }}
        </atom-button>
        <atom-button class="button--circle--white" @click="onClickCreateCancel">
          キャンセル
        </atom-button>
      </template>
    </modal>

    <modal v-model:is-visible="displayCompleteModal">
      <template #header> 登録完了 </template>
      <template #body> 登録が完了しました。 </template>
      <template #footer>
        <atom-button @click="onClickComplete">OK</atom-button>
      </template>
    </modal>
    <modal v-model:is-visible="displayCancelModal">
      <template #header> 送信予約を取り消してよいですか？ </template>
      <template #body>
        送信予約を取り消した場合、このメールは削除されメールの配信は行われません。
      </template>
      <template #footer>
        <atom-button @click="onClickDelete">送信予約を取り消す</atom-button>
        <atom-button class="button--circle--white" @click="onClickDeleteCancel">
          キャンセル
        </atom-button>
      </template>
    </modal>
  </validation-observer>
</template>

<script>
import format from "date-fns/format";
import times from "lodash/times";
export default {
  name: "MailSendMain",
  props: {
    title: {
      type: String,
      default: undefined,
    },
    defaultSubject: {
      type: String,
      default: undefined,
    },
    defaultBody: {
      type: String,
      default: undefined,
    },
    defaultSendNow: {
      type: Number,
      default: undefined,
    },
    defaultSendDateTime: {
      type: Date,
      default: undefined,
    },
    disableSubject: {
      type: Boolean,
      default: false,
    },
    disableBody: {
      type: Boolean,
      default: false,
    },
    disableSendDateTime: {
      type: Boolean,
      default: false,
    },
    loginUrl: {
      type: String,
      default: undefined,
    },
    needPassword: {
      type: Boolean,
      default: undefined,
    },
    mailSignature: {
      type: String,
      default: undefined,
    },
    showConfirmModal: {
      type: Boolean,
      default: undefined,
    },
    showCompleteModal: {
      type: Boolean,
      default: undefined,
    },
    showCancelModal: {
      type: Boolean,
      default: undefined,
    },
    confirmButtonLabel: {
      type: String,
      default: "送信予約",
    },
    doCancel: {
      type: Boolean,
      default: false,
    },
    necessaryValidation: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
      default:
        "送信開始日時を指定する場合、その日時はご利用の環境のタイムゾーンに準拠した日時として扱います。\r\n指定された送信時刻からメール送信を開始しますが、弊社メールサーバのメール送信件数が多い場合、メール送信完了まで時間がかかる場合がございます。",
    },
  },
  emits: ["click-create", "click-delete"],
  data: function () {
    return {
      input: {
        subject: undefined,
        body: undefined,
        sendNow: "now",
        sendDateTime: undefined,
      },
      displayConfirmModal: false,
      displayCompleteModal: false,
      displayCancelModal: false,
      defaultValue: new Date(),
      times: times,
    };
  },
  computed: {
    sendReservedAt: function () {
      return this.sendNow ? null : this.input.sendDateTime;
    },
    modalSendReservedAt: function () {
      return this.sendNow
        ? "今すぐ"
        : format(this.input.sendDateTime, "yyyy/MM/dd HH:mm");
    },
    requiredDateTime: function () {
      return this.sendNow ? { required: false } : { required: true };
    },
    sendNow: function () {
      return this.input.sendNow == "now";
    },
  },
  watch: {
    defaultSubject: function () {
      this.input.subject = this.defaultSubject;
    },
    defaultBody: function () {
      this.input.body = this.defaultBody;
    },
    defaultSendNow: function () {
      this.sendNow = !!+this.defaultSendNow;
    },
    defaultSendDateTime: function () {
      this.input.sendDateTime = this.defaultSendDateTime;
      this.input.sendNow =
        this.defaultSendDateTime === undefined ? "now" : "specify";
    },
    showConfirmModal: function () {
      this.displayConfirmModal = this.showConfirmModal;
    },
    showCompleteModal: function () {
      this.displayCompleteModal = this.showCompleteModal;
    },
    showCancelModal: function () {
      this.displayCancelModal = this.showCancelModal;
    },
  },
  methods: {
    notBeforeToday(date) {
      return date < new Date().setHours(0, 0, 0, 0);
    },
    notBeforeTime(date) {
      var today = new Date();
      today.setMinutes(today.getMinutes() - 1);
      return date <= today;
    },
    onChangeSendNow() {
      if (this.sendNow) {
        this.input.sendDateTime = undefined;
      }
    },
    onClickNext() {
      this.displayConfirmModal = true;
    },
    onClickCreate() {
      this.$emit("click-create", this.input, this.sendReservedAt);
    },
    onClickCreateCancel() {
      this.displayConfirmModal = false;
    },
    onClickComplete() {
      this.displayCompleteModal = false;
    },
    onClickSendCancel() {
      this.displayCancelModal = true;
    },
    onClickDeleteCancel() {
      this.displayCancelModal = false;
    },
    onClickDelete() {
      this.$emit("click-delete");
    },
  },
};
</script>

<style lang="scss">
.section-label .v3dp__input_wrapper {
  width: 160px !important;
}
.section-label .v3dp__input_wrapper .v3dp__clearable {
  top: 7px !important;
}
</style>
<style lang="scss" scoped>
.modal-text {
  white-space: nowrap;
}
.mail-body {
  white-space: pre-wrap;
  word-wrap: break-word;
}
.send-start-date__label {
  display: block;
}
.send-start-date__input {
  display: block;
}
.send-start-date__input label {
  margin-left: 5px;
  margin-right: 16px;
}
.notice-message {
  margin-left: 20px;
  font-size: 0.875rem;
}
.board__button > *:not(:first-of-type) {
  margin-left: 1rem;
}
.send-date-time-wrap {
  padding: 16px;
  margin-top: 16px;
  background-color: #f8f8f8;
  border-radius: 4px;
}
.section {
  padding-bottom: 16px;
}
.section.last-section {
  padding-bottom: 0;
}
.section > label {
  display: block;
  padding-bottom: 8px;
}
.section-label {
  display: inline-flex;
  flex-flow: column;
}
.section-label > span {
  padding-bottom: 8px;
}
.send-start-date__input > input#send-specify[disabled] {
  border-color: #ddd;
  background-color: #f7f7f7;
}
.send-start-date__input > label.disabled {
  color: #ababab;
}
.footer-description {
  white-space: pre-wrap;
}
</style>
