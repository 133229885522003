export default {
  params: ["key", "itemList"],
  validate(value, [ key, itemList ]) {
    if (!value || !itemList) {
      return true;
    }

    // 第２階層以降はリストのリストのため、flat化
    // IE11でflatが使用不可のためreduce、concatで代用 2021/7/7
    // 自分以外で重複があればNG
    return !Object.values(itemList)
      .reduce((acc, val) => acc.concat(val), [])
      .some((item) => item.id != key && String(item.reportUnitCode) === value);
  },
};
